import { render, staticRenderFns } from "./detailsMovements.vue?vue&type=template&id=25796921&style=background%3A%20%23e5e5e5&"
import script from "./detailsMovements.vue?vue&type=script&lang=js&"
export * from "./detailsMovements.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports